import { ComponentRef, EditorReadyFn, EditorReadyOptions, EditorSDK, PageRef } from '@wix/platform-editor-sdk';

import { interactionEnded, interactionFailed, interactionStarted } from '../../../utils/monitoring';
import {
  APP_TOKEN,
  SANTA_MEMBERS_APP_ID,
  PROFILE_PAGE_BOB_APP_DEF_ID,
  MEMBERS_PAGES_GROUP_NAME,
} from '../../constants';
import { startSequentialPromises, stopSequentialPromises } from '../../enforceSequentiality';
import { allSettled } from '../../../utils/promises';
import { createController } from '../../wrappers/controllers';
import { createLoginMenu, createLoginIconsMenu } from '../../wrappers/menus';
import { create as createPageGroup, addPageToGroup } from '../../wrappers/pagesGroup';
import { getMembersAreaPage } from '../../wrappers/pages';
import { addLoginButton, registerToComponentAddedToStageEvent } from '../../wrappers/components';
import { getIsResponsiveEditor } from '../../services/applicationState';

const createMembersPageGroup = (editorSDK: EditorSDK) => {
  return createPageGroup(editorSDK, MEMBERS_PAGES_GROUP_NAME);
};

const addMemberAreaPageToPageGroup = async (editorSDK: EditorSDK) => {
  const membersAreaPage = await getMembersAreaPage(editorSDK);
  if (!membersAreaPage) {
    throw new Error('Members Area Page is not installed');
  }
  return addPageToGroup(editorSDK, MEMBERS_PAGES_GROUP_NAME, membersAreaPage.id!);
};

const addMembersAreaPage = (editorSDK: EditorSDK) => {
  return editorSDK.document.application.add(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
    managingAppDefId: SANTA_MEMBERS_APP_ID,
    shouldNavigate: true,
    isSilent: true,
  });
};

const getRefsForLoginMenu = async (editorSDK: EditorSDK) => {
  const [masterRef, headerRef]: [PageRef, ComponentRef] = await allSettled([
    editorSDK.siteSegments.getSiteStructure(APP_TOKEN),
    editorSDK.siteSegments.getHeader(APP_TOKEN),
  ]);
  let controllerRef: ComponentRef | null = null;

  if (!getIsResponsiveEditor()) {
    controllerRef = await createController(editorSDK, masterRef);
  }

  return { headerRef, controllerRef };
};

const addLoginMenus = async (editorSDK: EditorSDK) => {
  const { headerRef, controllerRef } = await getRefsForLoginMenu(editorSDK);

  await Promise.all([
    createLoginMenu(editorSDK),
    createLoginIconsMenu(editorSDK),
    addLoginButton(editorSDK, controllerRef!, headerRef),
  ]);
};

const maybeInstallMembersArea = async (editorSDK: EditorSDK, options: EditorReadyOptions) => {
  if (options.firstInstall) {
    await createMembersPageGroup(editorSDK);
    await addMembersAreaPage(editorSDK);
    await addMemberAreaPageToPageGroup(editorSDK);
    await addLoginMenus(editorSDK);
  }
};

export const editorReady: EditorReadyFn = async (editorSDK, _, options) => {
  try {
    interactionStarted('editorReady');
    await maybeInstallMembersArea(editorSDK, options);
    await registerToComponentAddedToStageEvent(editorSDK);
    startSequentialPromises();
    interactionEnded('editorReady');
  } catch (error: any) {
    interactionFailed('editorReady', error);
    console.error('Members Area installation failed');
    console.error(error);
    stopSequentialPromises();
    throw error;
  }
};
