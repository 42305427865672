import { EditorReadyFn, GetAppManifest } from '@wix/platform-editor-sdk';

import { MembersAreaOnEventFn } from '../types/EditorAppModule';
import { initializeMonitoring } from '../utils/monitoring';
import { maybeConductExperiments, shouldUseMembersAreaOnBlocksInstallFlow } from '../utils/experiments';
import { parseStaticsUrlFromEditorScriptUrl } from './services/urls';
import * as applicationState from './services/applicationState';
import { currentMembersArea, membersAreaOnMSB, exportsFn, getControllerPresets } from './platform-app';

const getEditorPlatformApp = async () => {
  const isMembersAreaOnMSBEnabled = await shouldUseMembersAreaOnBlocksInstallFlow();
  return isMembersAreaOnMSBEnabled ? membersAreaOnMSB : currentMembersArea;
};

const editorReady: EditorReadyFn = async (editorSDK, _appToken, options) => {
  maybeConductExperiments();

  applicationState.setEditorSDK(editorSDK);
  applicationState.setStaticsBaseUrl(parseStaticsUrlFromEditorScriptUrl(options.initialAppData.editorScriptUrl));
  // @ts-expect-error - blogWriterProfilesOnly does not exist on Origin.info type in Editor platform sdk types
  applicationState.setIsBlogWriterProfilesOnly(!!options.origin.info?.blogWriterProfilesOnly);
  applicationState.setIsResponsiveEditor(options.origin.type === 'RESPONSIVE');
  applicationState.setIsADI(options.origin.type.indexOf('ADI') === 0);

  try {
    await initializeMonitoring(editorSDK, options);
  } catch (e) {}

  const platformApp = await getEditorPlatformApp();
  return platformApp.editorReady(editorSDK, _appToken, options);
};

const onEvent: MembersAreaOnEventFn = async (event, editorSDK) => {
  const platformApp = await getEditorPlatformApp();
  return platformApp.onEvent(event, editorSDK);
};

const getAppManifest: GetAppManifest = async (options, editorSDK, contextParams) => {
  const platformApp = await getEditorPlatformApp();
  return platformApp.getAppManifest(options, editorSDK, contextParams);
};

export { editorReady, onEvent, getAppManifest, getControllerPresets, exportsFn as exports };
