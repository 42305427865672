import { MembersAreaPlatformApp } from '../types';
import { currentMembersArea } from '../current-ma';
import { editorReady } from './editor-ready';

// TODO: Build manifest and event handlers for MA on blocks flow.
export const membersAreaOnMSB: MembersAreaPlatformApp = {
  editorReady,
  getAppManifest: currentMembersArea.getAppManifest,
  onEvent: currentMembersArea.onEvent,
};
